/**
 * Contains the API endpoint URLs used in the application.
 *
 * @constant {Object}
 * @property {string} ROLLOVER - The endpoint for rollover-related operations.
 * @property {string} ROUTE - The endpoint for route-related operations.
 */
const URLS = {
  ROLLOVER: '/rollover/',
  ROUTE: '/route/',
  ROUTE_EVENT: '/route-events/',
  STUDENT_DATA: '/get-rollover-student-data/',
  PLUGIN_ACTIVE: '/active-plugin/upload-csv/',
  PREVIEW_URL: '/rollover-preview/',
  CSV_STATS: '/get-csv-upload-stats/',
};

export default URLS;
