// Application Imports
import GraniteAPIClient from 'granite-admin/utils/granite-client';
import { env } from 'granite-admin/env';

import URLS from './end-point.js';

/* eslint-disable */
const url = env.REACT_APP_API_BASE_URL + env.REACT_APP_ROLLCALL_API;
const konnectPreviewUrl = env.REACT_APP_KONNECT_BULK_UPLOAD_BASE_URL;
const token = env.REACT_APP_BEARER_TOKEN_KONNECT;
const rolloverUrl = env.REACT_APP_ROLLOVER_BASE_URL;

/**
 * Fetches rollover data from the API.
 *
 * @param {Object} [params] - Optional query parameters to include in the request. If not provided, defaults to `undefined`.
 * @returns {Promise<Object>} - A promise that resolves to the data returned by the API.
 */
async function getRollOver(params) {
  return (await GraniteAPIClient(url).get(URLS.ROLLOVER, params ? { params } : undefined))?.data;
}

/**
 * Submits rollover data to the API. Uses PATCH if a primary key (`pk`) is provided; otherwise, uses POST.
 *
 * @param {Object} param - The data to be submitted. If it includes a `pk`, PATCH is used; otherwise, POST is used.
 * @param {number} [param.pk] - Optional primary key for updating an existing resource.
 * @returns {Promise<Object>} - A promise that resolves to the data returned by the API.
 */
async function postRollOver(param) {
  const { pk, ...payload } = param;
  const method = pk ? 'patch' : 'post';
  const endpoint = pk ? `${URLS.ROLLOVER}${pk}/` : URLS.ROLLOVER;
  return (await GraniteAPIClient(url)[method](endpoint, payload))?.data;
}

/**
 * Fetches route group list data from the API.
 *
 * @param {Object} [params] - Optional query parameters to include in the request.
 * @returns {Promise<Object>} - A promise that resolves to the data returned by the API.
 */
async function getRouteGroupList(params) {
  return (await GraniteAPIClient(url).get(URLS.ROUTE, { params }))?.data;
}

async function getRouteEvent(params) {
  return (await GraniteAPIClient(url).get(URLS.ROUTE_EVENT, params))?.data;
}

async function startRollover(payload, org) {
  const headers = { organisation: org };
  return (await GraniteAPIClient(rolloverUrl, headers).post('', payload))?.data;
}

async function getRolloverStudentData(params) {
  return (await GraniteAPIClient(url).get(URLS.STUDENT_DATA, params ? { params } : undefined))?.data;
}

async function getActivePlugin() {
  return (await GraniteAPIClient(url).get(URLS.PLUGIN_ACTIVE))?.data;
}

async function previewStudents(payload) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return (await GraniteAPIClient(konnectPreviewUrl, headers).post(URLS.PREVIEW_URL, payload))?.data;
}

async function CsvStats(params) {
  return (await GraniteAPIClient(url).get(URLS.CSV_STATS, params ? { params } : undefined))?.data;
}

export default {
  getRollOver,
  postRollOver,
  getRouteGroupList,
  getRouteEvent,
  startRollover,
  getRolloverStudentData,
  getActivePlugin,
  previewStudents,
  CsvStats,
};
