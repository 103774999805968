import styled from 'styled-components';
import { Text, Layer, Header, List, Menu, Box, DropButton, AccordionPanel, Avatar } from 'grommet';
import { Bell } from '@styled-icons/fa-solid/Bell';

import MenuButton from '../components/MenuButton';

const StyledLayer = styled(Layer)`
  background: transparent;
  border: none;
  position: center;
`;
const StyledIframe = styled.iframe`
  background: transparent;
  border: none;
`;

const StyledMenuButton = styled(MenuButton)`
  &:hover {
    background: none;
    border-bottom: ${({ theme }) => `1px solid ` + theme.global.colors['sidebar-svg-active'] + `!important`};
  }
`;

const HeaderBox = styled(Header)`
  top: 0;
  left: 0;
  z-index: 1;
  padding: 0px 10px;
`;

const StyledList = styled(List)`
  & li {
    border: none;
    padding-left: 0;
    padding-right: 0;
    & span {
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
    }
  }
`;
const StyledMenu = styled(Menu)`
  &:hover {
    background: none;
    border-bottom: ${({ theme }) => `1px solid ` + theme.global.colors['sidebar-svg-active'] + `!important`};
  }
`;

const StyledSpan = styled.span`
  svg {
    fill: ${({ isActive, theme }) =>
      isActive
        ? theme.global.colors['sidebar-svg-active'] + `!important`
        : theme.global.colors['sidebar-svg'] + `!important`};
  }
`;

const StyledText = styled(Text)`
  color: ${({ isActive, theme, item }) =>
    item?.extra_data?.customColor
      ? theme.global.colors['action-buttons-background']
      : isActive
      ? theme.global.colors['sidebar-label-active']
      : theme.global.colors['sidebar-label']};

  font-weight: ${({ theme, item }) =>
    item?.extra_data?.customColor
      ? 'bold'
      : theme.global.colors['sidebar-label-bold']
      ? theme.global.colors['sidebar-label-bold']
      : 'normal'};
`;

const StyledBox = styled(Box)`
  &:hover svg {
    fill: ${({ isActive, theme }) => theme.global.colors['sidebar-svg-hover'] + `!important`};
  }
  &:hover span {
    color: ${({ isActive, theme, item }) =>
      item?.extra_data?.customColor
        ? theme.global.colors['action-buttons-background']
        : theme.global.colors['sidebar-label-hover'] + `!important`};
  }
`;

const StyledSpanMoreLeft = styled.span`
  padding-top: 3px;
  svg {
    fill: ${({ isActive, theme }) =>
      isActive
        ? theme.global.colors['sidebar-svg-active'] + `!important`
        : theme.global.colors['sidebar-svg'] + `!important`};
  }
`;
const StyledBoxMoreLeft = styled(Box)`
  width: 170px;
  padding: 10px 10px;
  background-color: #fff;
  white-space: nowrap;
  &:hover {
    background: none;
    border-bottom: ${({ theme }) => `1px solid ` + theme.global.colors['sidebar-svg-active'] + `!important`};
  }
`;
const StyledDropButtonMoreLeft = styled(DropButton)`
  padding: 0.5rem;
  margin: 0;
  &:hover {
    background: none;
    border-bottom: ${({ theme }) => `1px solid ` + theme.global.colors['sidebar-svg-active'] + `!important`};
  }
`;
const StyledMenuNotification = styled(DropButton)`
  padding: 0 5px;
  svg {
    color: ${({ isActive, theme }) => theme.global.colors['sidebar-svg-hover'] + `!important`};
  }
  // &:hover .bell-count {
  //   background: ${({ isActive, theme }) => theme.global.colors['sidebar-svg-hover'] + `!important`};
  // }
  // &:hover .bell-count span {
  //   color: ${({ isActive, theme }) => theme.global.colors['sidebar-icon'] + `!important`};
  // }
  // &:hover span {
  //   color: ${({ isActive, theme }) => theme.global.colors['sidebar-label-hover'] + `!important`};
  // }
`;

const AnimationBox = styled(Box)`
  ${({ animatedIcon }) => {
    if (animatedIcon)
      return `
    animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;

  @-webkit-keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }`;
  }}
`;

const StyledTextNotification = styled(Text)`
  a {
    color: #0e84ad;
    text-decoration: underline;
  }
  a:hover {
    color: #fbab31;
  }
`;

const AvatarBoxNotification = styled(Avatar)`
  position: relative;
`;
const AvatarBellNotification = styled(Bell)`
  color: ${({ theme, background }) => theme.global.colors[background]};
`;
const StatusIconNotification = styled(Box)`
  position: absolute;
  top: 4px;
  right: 12px;
`;
const StyledSpanRightItems = styled.span`
  display: flex;
  margin-top: ${({ label }) => (label === 'Messaging' ? '1px' : '0')};
  padding-right: ${({ label }) => (label === 'Messaging' ? '2px' : '0')};
  color: ${({ btncolor }) => btncolor || 'inherit'};
`;
const StyledAccordionPanelMessageRoute = styled(AccordionPanel)`
  background: white;
  & h5 {
    font-size: 14px;
  }
  & svg {
    height: 15px;
    color: #868686;
  }
`;

const StyledBoxMessageRoutes = styled(Box)`
  flex-direction: row;
  gap: small;
  align-items: center;
  background: #ff00004d;
  padding: 6px;
`;
export {
  StyledLayer,
  StyledIframe,
  StyledMenuButton,
  HeaderBox,
  StyledList,
  StyledMenu,
  StyledSpan,
  StyledText,
  StyledBox,
  StyledSpanMoreLeft,
  StyledBoxMoreLeft,
  StyledDropButtonMoreLeft,
  AnimationBox,
  StyledMenuNotification,
  StyledTextNotification,
  AvatarBoxNotification,
  AvatarBellNotification,
  StatusIconNotification,
  StyledSpanRightItems,
  StyledAccordionPanelMessageRoute,
  StyledBoxMessageRoutes,
};
