// Core Imports
import graniteAPIClient from 'granite-admin/utils/granite-client';

// Application Imports
import { ROLLCALL_API_URL, PLUGIN_API_URL, ORGANISATION_API_URL } from 'common/completeUrls';
import {
  URLS,
  PLUGIN_URLS,
  ROUTE_MAPPING_URLS,
  ROUTE_URLS,
  STOPS_URLS,
  CAMPUS_URLS,
  TRANSPORT_URLS,
} from 'common/commonGatewaysControllers/gateways/endpoints';

/**
 * Validates if a phone number is already in use by a user.
 *
 * @param {object} payload - object with new_phone and user_id
 * @param {string} payload.new_phone - phone number of the user
 * @param {string} payload.user_id - id of the user
 *
 * @returns {Promise<Object>} - with boolean flag indicating if phone is in use
 */
async function userPhoneValidation(payload) {
  return await graniteAPIClient(ROLLCALL_API_URL).post(URLS.USER_PHONE_VALIDATION, payload);
}

/**
 * Fetches the event groups
 *
 * @param {object} payload - page and page_size
 *
 * @returns {Promise<Object>} - with list of event groups
 */
async function getEventGroups(payload) {
  return await graniteAPIClient(ROLLCALL_API_URL).get(URLS.ROUTE_GROUP, payload);
}

//function to get Assigned stops
async function getStopsAllocatedToRoute(payload) {
  const { data } = await graniteAPIClient(ROLLCALL_API_URL).get(ROUTE_MAPPING_URLS.ROUTE_STOP_MAPPING, payload);
  return data;
}

async function getSettings(payload) {
  return await graniteAPIClient(ORGANISATION_API_URL).get(URLS.SETTINGS, payload);
}

async function updateSettings(payload, pk) {
  return await graniteAPIClient(ORGANISATION_API_URL).put(`${URLS.SETTINGS}${pk}/`, payload);
}

/**
 * Post a new route
 * @param {object} payload - route data
 * @returns {object} - response data from server
 */
async function postRoute(payload) {
  const { data } = await graniteAPIClient(ROLLCALL_API_URL).post(ROUTE_URLS.ROUTE, payload);
  return data;
}
/**
 * Update a route using put method
 * @param {object} payload - route data
 * @param {number} pk - primary key of route to update
 * @returns {object} - response data from server
 */
async function updateRoute(payload, pk) {
  const { data } = await graniteAPIClient(ROLLCALL_API_URL).put(`${ROUTE_URLS.ROUTE}${pk}/`, payload);
  return data;
}
/**
 * Update a route using patch method
 * @param {object} payload - route data to be updated
 * @param {number} pk - primary key of route to update
 * @returns {object} - response data from server
 */
async function updateRoutePatch(payload, pk) {
  const { data } = await graniteAPIClient(ROLLCALL_API_URL).patch(`${ROUTE_URLS.ROUTE}${pk}/`, payload);
  return data;
}

/**
 * Activates a plugin
 * @param {number} id - primary key of plugin to activate
 * @returns {object} - response data from server
 */
async function activatePlugin(id) {
  const { data } = await graniteAPIClient(PLUGIN_API_URL).post(`${PLUGIN_URLS.PLUGIN}${id}${PLUGIN_URLS.ACTIVATE}`);
  return data;
}

/**
 * Get list of routes
 * @param {object} params - query parameters
 * @returns {object} - response data from server
 */
async function getRoutes(payload) {
  const { data } = await graniteAPIClient(ROLLCALL_API_URL).get(ROUTE_URLS.ROUTE, payload);
  return data;
}

/**
 * Get list of Orgnisation roles
 * @param {object} params - query parameters
 * @returns {object} - response data from server
 */
async function getRoles(payload) {
  const { data } = await graniteAPIClient(ORGANISATION_API_URL).get('/organisation/roles/', payload);
  return data;
}
/**
 * Fetches all stops from API
 *
 * @param {Object} payload - The object containing the payload
 *
 * @returns {Promise} - A promise resolving to an object containing the
 *                     stops data, its count.
 */
async function getStopsList(payload) {
  return await graniteAPIClient(ROLLCALL_API_URL).get(STOPS_URLS.LIST_STOPS, payload);
}

/**
 * Fetches a single stop from API
 *
 * @param {Number} pk - The primary key of the stop
 *
 * @returns {Promise} - A promise resolving to an object containing the
 *                     stop data
 */
async function getOneStop(pk) {
  return await graniteAPIClient(ROLLCALL_API_URL).get(`${STOPS_URLS.STOP}${pk}/`);
}

/**
 * Deletes a stop from API
 *
 * @param {Number} stopId - The primary key of the stop
 * @returns {Promise} - A promise resolving to an object containing the
 *                     deleted stop data
 */
async function deleteStop(stopId) {
  return await graniteAPIClient(ROLLCALL_API_URL).delete(`${STOPS_URLS.STOP}${stopId}/`);
}

/**
 * Fetches all campuses from API
 *
 * @param {Object} payload - The object containing the payload
 * @returns {Promise} - A promise resolving to an object
 *                     containing the campus data, its count.
 */
async function getCampus(payload) {
  return await graniteAPIClient(ROLLCALL_API_URL).get(CAMPUS_URLS.CAMPUS, payload);
}

/**
 * Fetches a single route from API
 * @param {Number} pk - The primary key of the route
 * @returns {Promise} - A promise resolving to an object containing the
 *                     route data
 */
async function getSingleRoute(pk) {
  return await graniteAPIClient(ROLLCALL_API_URL).get(`${ROUTE_URLS.ROUTE}${pk}/`);
}

/**
 * Fetch the stops arrival times and returns data property from response.
 */
async function stopsArrivalTime(routeIds) {
  const { data } = await graniteAPIClient(ROLLCALL_API_URL).get(
    `${ROUTE_MAPPING_URLS.LIST_ROUTE_STOP_MAPPING}?route_ids=${routeIds}`,
  );
  return data;
}

/* Fetches the list of students
 *
 * @param {object} payload - page, page_size
 *
 * @returns {Promise<Object>} - with list of students
 */
async function getStudent(payload) {
  return await graniteAPIClient(ROLLCALL_API_URL).get(URLS.LIST_STUDENT, payload);
}

/**
 *Fetch the org's list.
 *
 * @param {object} - page, page_size
 *
 * @returns {object} - with list of org's
 */
async function getParticularOrganisations(payload) {
  return await graniteAPIClient(ORGANISATION_API_URL, { organisation: undefined }).get(
    URLS.BUSINESS_ADMIN_ORGANISATION,
    payload,
  );
}

//function to get bus List in dropDown
async function getListRoutes(payload) {
  const { data } = await graniteAPIClient(ROLLCALL_API_URL).get(ROUTE_URLS.LIST_ROUTE, payload);
  return data;
}

/**
 * Fetches the list of transports
 * @returns {Promise<Object>} - with list of transports
 */
async function getTransport(payload) {
  const { data } = await graniteAPIClient(ROLLCALL_API_URL).get(TRANSPORT_URLS.TRANSPORT, payload);
  return data;
}
export default {
  userPhoneValidation,
  getEventGroups,
  getStopsAllocatedToRoute,
  updateRoute,
  postRoute,
  updateRoutePatch,
  activatePlugin,
  getRoutes,
  getRoles,
  getParticularOrganisations,
  getStudent,
  getStopsList,
  getOneStop,
  deleteStop,
  getCampus,
  getSingleRoute,
  stopsArrivalTime,
  getSettings,
  updateSettings,
  getParticularOrganisations,
  getListRoutes,
  getTransport,
};
